import React, { useContext, useState } from 'react';
import { toast } from "react-toastify";
import { UserContext } from '../UserContext';
import { signInWithEmailAndPassword, getIdToken } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig'; 
import { signOut } from "firebase/auth";
import Loader from '../components/Loader';

type Props = {}

const Login = (props: Props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const { setToken, setUserData } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    // Function to fetch user data
    const fetchUserData = async (token: string) => {
        try {
            const response = await fetch(`https://backend.livecosmetics.pro/api/user/getInfo/uid`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,  
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const data = await response.json();
            console.log(data.user.role === 'admin')
            if (data.user.role === 'admin') {
              toast.success("Logged in successfully!");
              setUserData(data);
              setToken(token);
              setLoading(false);
            } else {
              toast.error("Access denied. You are not an admin.");
              await signOut(auth); 
              setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            toast.error("Access denied. Please try again later.");
            setError("Failed to fetch user data");
            setLoading(false);
        }
    };

    // Function to handle login
    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!email || !password) {
            toast.error('Please enter both email and password');
            return;
        }

        try {
            setLoading(true);
            // Sign in with Firebase using email and password
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Get the Firebase ID token
            const token = await getIdToken(user);

            // Fetch user data from the backend
            await fetchUserData(token);

        } catch (error) {
            console.error("Error signing in:", error);
            setError("Login failed. Please check your credentials.");
            toast.error("Login failed. Please check your credentials.");
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 w-full">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-bold text-mainTextColor font-poppins">Login</h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleLogin}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-mainTextColor">
                            Email
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-mainTextColor">
                            Password
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
                        />
                    </div>
                    <div>
                        <button
                          disabled={loading}
                            type="submit"
                            className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-500  focus:outline-none hover:bg-blue-500"
                        >
                          {
                            loading ? 
                            <div className='w-full h-full items-center justify-center flex'>
                              <Loader size='small'/>
                            </div> : 
                            "Login"
                          }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
