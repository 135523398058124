import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Header from '../../components/Header';
import Search from '../../components/Search';
import { UserContext } from '../../UserContext';
import Loader from '../../components/Loader';

const Users: React.FC = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');
  const [users, setUsers] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [size] = useState<number>(10); // Size of items per page
  const [totalPages, setTotalPages] = useState<number>(1);
  const [editingUserId, setEditingUserId] = useState<string | null>(null); // Track which user is being edited
  const observer = useRef<IntersectionObserver | null>(null);
  const { token } = useContext(UserContext); // Get token from UserContext
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUsers(true);
  }, [search]);

  useEffect(() => {
    if (page !== 1) {
      fetchUsers(false);
    }
  }, [page]);

  const fetchUsers = async (reset: boolean = false) => {
    try {
      let fetchUrl = `https://backend.livecosmetics.pro/api/user/getAllUsers?search=${search}&limit=${size}`;

      if (reset) {
        fetchUrl += `&page=1`;
      } else {
        fetchUrl += `&page=${page}`;
      }

      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      });
      const data = await response.json();

      console.log(data)

      if (data.users) {
        setUsers((prevUsers) => (reset ? data.users : [...prevUsers, ...data.users]));
        setTotalPages(data.totalPages);
      }

      if (reset) {
        setPage(1);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
      toast.error('Error fetching users');
    }
  };

  const lastUserRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [page, totalPages]);

  const handleEdit = (userId: string) => {
    navigate(`/users/edit/${userId}`);
  };

  const handleDelete = async (userId: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this user?');
    if (confirmDelete) {
      try {
        let fetchUrl = `https://backend.livecosmetics.pro/api/user/${userId}/deleteUser`;
        const response = await fetch(fetchUrl, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
        });

        setPage(1);
        setUsers([]);
        fetchUsers(true);
      } catch (error) {
        toast.error((error as Error).message);
      }
    }
  };

  const handleLoyaltyPointsUpdate = async (userId: string, newPoints: number) => {
    try {
      const response = await fetch(`https://backend.livecosmetics.pro/api/user/editLoyalty`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`, 
        },
        body: JSON.stringify({
          userId,      
          points: newPoints 
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update loyalty points');
      }
  
      toast.success('Loyalty points updated successfully!');
    } catch (error) {
      console.error('Error updating loyalty points:', error);
      toast.error('Failed to update loyalty points');
    }
  };
  

  const handleLoyaltyPointsChange = (userId: string, points: string) => {
    const updatedUsers = users.map(user => {
      if (user._id === userId) {
        return { ...user, loyaltyPoints: parseInt(points) || 0 };
      }
      return user;
    });
    setUsers(updatedUsers);
  };

  const startEditing = (userId: string) => {
    setEditingUserId(userId);
  };

  const finishEditing = (userId: string) => {
    const user = users.find(user => user._id === userId);
    if (user) {
      handleLoyaltyPointsUpdate(userId, user.loyaltyPoints);
    }
    setEditingUserId(null);
  };

  return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
      <Header title={'Users'} button={false} buttonTitle={''} action={''} />
      <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
        <Search search={search} setSearch={setSearch} placeholderText={'Search by name or email'} />
        <div className='w-full overflow-visible flex-grow'>
          <div className="w-full overflow-visible">
            <div className='w-full grid grid-cols-6 font-bold text-mainTextColor my-4'>
              <div>Name</div>
              <div>Email</div>
              <div>Phone</div>
              <div>Role</div>
              <div>Email Updates</div>
              <div>Loyalty Points</div>
            </div>
            <hr className='border-t border-[#cdcdd0]' />
            {
              loading ? 
              <div className='items-center justify-center flex w-full h-[200px]'>
                <Loader size={'large'} />
              </div> : (
                users.map((user, index) => (
                  <div
                    key={user._id}
                    className='w-full grid grid-cols-6 my-4'
                    ref={index === users.length - 1 ? lastUserRef : null}
                  >
                    <div className='text-TextBlue flex items-center'>
                      <p className='underline'>{user.firstName} {user.lastName}</p>
                    </div>
                    <div className='text-TextBlue flex items-center'>
                      <p className=''>{user.email}</p>
                    </div>
                    <div className='text-TextBlue flex items-center'>
                      <p className=''>{user.phone || 'N/A'}</p>
                    </div>
                    <div className='text-TextBlue flex items-center'>
                      <p className=''>{user.role}</p>
                    </div>
                    <div className='text-TextBlue flex items-center'>
                      <p className=''>{user.emailUpdates ? "Yes" : "No"}</p>
                    </div>
                    <div className='text-TextBlue flex items-center'>
                      {editingUserId === user._id ? (
                        <div className='flex items-center w-full items-center flex'>
                          <input
                            type='number'
                            value={user.loyaltyPoints}
                            onChange={(e) => handleLoyaltyPointsChange(user._id, e.target.value)}
                            className='border p-1 rounded w-16 appearance-none'
                            // style={{ '-moz-appearance': 'textfield' }}s
                          />
                          <button
                            onClick={() => finishEditing(user._id)}
                            className="ml-2 text-[#1490C2] underline ml-5"
                          >
                            Done
                          </button>
                        </div>
                      ) : (
                        <div className='flex items-center w-full items-center flex'>
                          <p className='mr-2'>{user.loyaltyPoints}</p>
                          <button
                            onClick={() => startEditing(user._id)}
                            className='text-[#1490C2] underline ml-5'
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
